<template>
  <vue-perfect-scrollbar :settings="{ suppressScrollX: true, wheelPropagation: false }" class="h-100 rtl-ps-none ps scroll" style="height: 100%">
    <div class="pa-5 relative">
      <v-app-bar class="pt-1" fixed flat>
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0 onlyNotifications">도움요청</h6>
          <slot name="notificationDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="helpData pa-5 mt-10">
      <!-- <div class="heading-label pa-0 mb-8">Notifications</div> -->

      <!-- {{ helpData }} -->
      <!-- <div v-for="(item, index) in items" :key="index"> -->
      <div v-for="(item, index) in helpData" :key="index">
        <div class="d-flex justify-space-between mb-8">
          <div class="d-flex">
            <img
              class="mr-md rounded-circle mr-2"
              height="30"
              width="30"
              :src="item.avatar"
            />
            <div>
              <p class="font-weight-medium text-body-2 ma-0">
                {{ item.title }}
              </p>
              <div>
                <span class="text--disabled text-caption">{{ item.regDateLabel }}</span>
                <div class="text-caption">{{ item.contactInfo }}</div>
                <div class="py-2 d-flex rounded">
                  {{ item.contents }}
                </div>
                <!-- 첨부된 이미지 처리 -->
                <div class="d-flex align-center mb-1">
                  <p class="imgBox" v-for="(uploadFile, index3) in item.uploadFiles" 
                    :key="index3" @click="imageClick(uploadFile)">
                    <img :src="uploadFile.fileUrl" />
                  </p>
                </div>
                <div>
                  <!-- {{ item.isComplete }} -->
                  <v-btn v-if="item.isComplete === 'Y'" depressed small
                    class="success--text"
                    color="lighten-5 success">처리됨</v-btn>
                  <v-btn v-else depressed small
                    class="warning--text"
                    color="lighten-5 warning" @click="saveComplete(item)">확인(처리)</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
        <v-divider class="py-1" />
      </div>

    </div>

    <DialogImageViewer ref="refDialogImageViewer" />
  </vue-perfect-scrollbar>
</template>
<script>
import DialogImageViewer from "@/components/commonV2/Dialog/DialogImageViewer.vue";
export default {
  components: {
    DialogImageViewer
  },
  props: {
    helpData: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      // items: [
      //   {
      //     iconName: "mdi-cloud-upload",
      //     iconColor: "bg-blue-200",
      //     iconColorText: "info--text",
      //     title: "Watson uploaded a file",
      //     file: require("@/assets/images/file-types/001-pdf.svg"),
      //     fileName: "progress_report.pdf",
      //     time: "15 min ago",
      //   },
      //   {
      //     iconName: "mdi-file-document",
      //     iconColor: "bg-blue-200",
      //     iconColorText: "info--text",
      //     title: "jhon Commented on task",
      //     file: require("@/assets/images/file-types/001-pdf.svg"),
      //     fileName: "progress_report.pdf",
      //     time: "5 min ago",
      //   },
      //   {
      //     iconName: "mdi-comment-alert",
      //     iconColor: "bg-orange-200",
      //     iconColorText: "warning--text",
      //     title: "jhon Commented on task",

      //     time: "45 min ago",
      //   },
      //   // {
      //   //   iconName: "mdi-cloud-upload",
      //   //   iconColor: "bg-blue-200",
      //   //   iconColorText: "info--text",
      //   //   title: "jhon Commented on task",
      //   //   file: require("@/assets/images/file-types/004-xlsx.svg"),
      //   //   fileName: "budget_report.xlsx",
      //   //   time: "15 min ago",
      //   // },
      //   // {
      //   //   iconName: "mdi-comment-alert",
      //   //   iconColor: "bg-orange-200",
      //   //   iconColorText: "warning--text",
      //   //   title: "jhon Commented on task",
      //   //   time: "45 min ago",
      //   // },
      // ],
    };
  },
  methods: {
    imageClick(item) {
      /** 이미지 파일이면 보이게 처리 */
      if (!this.$helper.checkImageFile(item.fileUrl)) {
        window.open(item.fileUrl, "_blank")
        return;
      }
      this.$refs.refDialogImageViewer.Open(item.fileUrl);
    },
    saveComplete(item) {
      // console.log('item', item);
      item.isComplete = "Y";
      this.$emit("saveComplete", item);
    }
  },
};
</script>
<style lang="scss">
.onlyNotifications {
  .app-admin-wrap-layout-1.sidebar-mini .v-toolbar {
    left: 0 !important;
  }
}

.helpData {
  .imgBox {
    padding: 5px 0 !important;

    img {
      width: 30px !important;
    }
  }
}
</style>
